import { createGlobalStyle, withTheme } from 'styled-components'

const GlobalStyles = createGlobalStyle`
    html {
        overflow-y: scroll;
    }

    body {
        margin: 0;
        font-family: "Source Sans Pro", sans-serif;
        font-weight: 300;
        color: ${props => props.theme.colors.DarkBlue};
    }

    p {
        margin: 0;
    }
`

export default withTheme(GlobalStyles)