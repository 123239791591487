const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px'
}

export const Device = {
    mobile: `screen and (min-width: ${size.mobileS}) and (max-width: ${size.mobileL})`,
    tablet: `screen and (min-width: ${size.tablet}) and (max-width: ${size.laptop})`,
    desktop: `screen and (min-width: ${size.laptopL})`,
};

const Colors = {
    LightBlue: "#0090DF",
    DarkBlue: "#002A5B",
    White: "#FFF",
    LightGray: "#F2F2F2",
    LightGray2: "#CFCFCF"
}

export const Theme = {
    colors: Colors
}