import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { darken } from "polished"

export const ButtonStyle = styled.button`
    position: relative;
    z-index: 10;
    background-color: ${props => props.theme.colors.LightBlue};
    padding: .5rem 1rem;
    border: 0;
    border-radius: 6px;
    transition: all 0.10s linear;
    &:hover {
        cursor: pointer;
        background-color: ${props => darken(0.1, props.theme.colors.LightBlue)};
    }

    a {
        color: ${props => props.theme.colors.White};
        text-decoration: none;
        font-weight: 700;
        font-size: 1rem;
    }
`

const Button = ({ link, text, className }) => {
    return (
        <div className={className}>
            <ButtonStyle>
                <Link to={link}>{text}</Link>
            </ButtonStyle>
        </div>
    )
}

export default Button