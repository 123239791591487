import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import Favicon from "./../../static/images/favicon.ico"

const SEO = ({ title, description }) => {
  const metaData = useStaticQuery(graphql`
      {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `)

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title ? title + ' | ' + metaData.site.siteMetadata.title : metaData.site.siteMetadata.title}</title>
      <meta name="description" content={description || metaData.site.siteMetadata.description} />
      <link rel="shortcut icon" type="image/png" href={Favicon} />
      <script async defer data-domain="zettl.app" src="https://plausible.io/js/plausible.js"></script>
    </Helmet>
  )
}

export default SEO