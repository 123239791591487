import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { ButtonStyle } from "../components/button"
import { Device } from "./theme"

import Logo from "./../components/logo"

const NavGridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

const LoginButton = styled(ButtonStyle)`
  justify-self: end;
  align-self: center;
  margin-right: 2rem;

  @media ${Device.mobile} {
    margin-right: 1rem;
  }
`

const LogoContainer = styled(Link)`
  margin: 1rem 0;
  padding-left: 2rem;
  align-self: center;

  @media ${Device.mobile} {
    padding-left: 1rem;
    img {
      height: 40px;
      width: auto;
    }
  }
`

const NavGrid = () => {
  return (
    <NavGridWrapper>
      <LogoContainer to={"/"}>
        <Logo width={110} />
      </LogoContainer>
      <LoginButton>
        <a href="/#download">Download</a>
      </LoginButton>
    </NavGridWrapper>
  )
}

export default NavGrid