import React from "react"
import { ThemeProvider } from "styled-components"

import { Theme } from "./theme"
import "./../../static/fonts/fonts.css"
import GlobalStyles from "./globalStyles"
import Footer from "./footer"
import SEO from "./SEO"

const Layout = ({ children }) => {
    return (
        <ThemeProvider theme={Theme}>
            <SEO />
            <GlobalStyles />
            {children}
            <Footer />
        </ThemeProvider>
    )
}

export default Layout