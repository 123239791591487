import React from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import { FeedbackFish } from '@feedback-fish/react'

import Logo from "./logo"
import { Device } from "./theme"

const FooterContainer = styled.div`
    display: grid;
    align-self: end;
    grid-template-columns: repeat(8, 1fr);
    background: rgb(0,104,162);
    background: ${({ theme }) => theme.colors.White};
    padding: 6rem 0;
    grid-gap: 4rem;

    @media ${Device.mobile} {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: repeat(auto-fill, 1fr);
        grid-gap: 1rem;
        padding: 3rem 0;
    }
`

const LogoContainer = styled.div`
    display: grid;
    grid-column: 3;

    @media ${Device.tablet} {
        grid-column: 2;
    }

    @media ${Device.mobile} {
        grid-column: 2;
        margin-bottom: 36px;

        a {
            img {
                height: 60px;
            }
        }
    }
`

const FooterLinksContainer = styled.div`
    display: grid;
    grid-column-start: 4;
    grid-column-end: 7;
    grid-template-columns: repeat(3, auto);
    justify-items: center;

    @media ${Device.tablet} {
        grid-column-start: 3;
        grid-column-end: 8;
    }

    @media ${Device.mobile} {
        grid-row: 2;
        grid-column-start: 1;
        grid-column-end: 4;
    }
`

const Column2 = styled.div`
    display: grid;
    grid-column: 1;
`

const Column3 = styled.div`
    display: grid;
    grid-column: 2;
`

const Column4 = styled.div`
    display: grid;
    grid-column: 3;
`

const FooterLinks = styled.div`
    h3 {
        margin-top: 0;
        margin-bottom: 1.5rem;
    }
    ul {
        list-style: none;
        padding: 0;
    }

    li {
        margin-bottom: 0.3rem;
    }
`

const LinkStyle = css`
    color: ${({ theme }) => theme.colors.DarkBlue};
    font-weight: 300;
    font-family: "Source Sans Pro", sans-serif;
    text-decoration: none;
    transition: all 0.10s linear;
    font-size: 1rem;

    &:hover {
        text-decoration: underline;
    }
`

const FooterLink = styled(Link)`
    ${LinkStyle}
`

const FeedbackButton = styled.button`
   ${LinkStyle}
   border: 0;
   background: none;
   padding: 0;
   margin: 0;
   cursor: pointer;
`

const Footer = () => {

    return (
        <FooterContainer>
            <LogoContainer>
                <Link to="/">
                    <Logo height="80" />
                </Link>
            </LogoContainer>
            <FooterLinksContainer>
                <Column2>
                    <FooterLinks>
                        <h3>zettl</h3>
                        <ul>
                            <li><FooterLink to="/faq">FAQ</FooterLink></li>
                            <li><FooterLink to="/versionen">Versionen</FooterLink></li>
                        </ul>
                    </FooterLinks>
                </Column2>
                <Column3>
                    <FooterLinks>
                        <h3>Downloads</h3>
                        <ul>
                            <li><FooterLink target="_blank" to={"https://apps.apple.com/de/app/zettl-die-zeiterfassung/id1528015686"}>AppStore</FooterLink></li>
                            {/* <li><FooterLink to={"#"}>PlayStore</FooterLink></li> */}
                        </ul>
                    </FooterLinks>
                </Column3>
                <Column4>
                    <FooterLinks>
                        <h3>About</h3>
                        <ul>
                            <li><FooterLink to="/kontakt">Kontakt</FooterLink></li>
                            <li><FooterLink to="/datenschutz">Datenschutz</FooterLink></li>
                            <li><FooterLink to="/imprint">Impressum</FooterLink></li>
                        </ul>
                    </FooterLinks>
                </Column4>
            </FooterLinksContainer>
        </FooterContainer>
    )
}

export default Footer